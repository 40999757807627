import React, { useEffect, useState } from 'react';
import { TfiArrowTopRight } from 'react-icons/tfi';
import {useNavigate, useParams} from 'react-router-dom';
import { BigNumber, ethers } from "ethers";
import { abi, contractAddress, nftContract } from '../../cryptoFishVendorWeb3';
import { PulseLoader } from 'react-spinners';
import { baseUrlEtherscan } from '../../constant';
import { useAccount, useContractReads, useContractWrite } from 'wagmi';
import { toast } from 'react-toastify';
import './MintingPage.css';

function MintingPage() {
    const [mintingQty, setMintingQty] = useState(1);
    const [mintLoading, setMintLoading] = useState(false);
    const [maxMintQty, setMaxMintQty] = useState();
    const [maxMintQtyLoading, setMaxMintQtyLoading] = useState(false);
    const [mintingAmount, setMintingAmount] = useState();
    const [balanceCalc, setBalanceCalc] = useState();
    const [mintAmountLoading, setMintAmountLoading] = useState(false);
    const [mintResultLink, setMintResultLink] = useState('');
    const [remainingWetList, setRemainingWetList] = useState(0);
    const [remainingWetListLoading, setRemainingWetListLoading] = useState(false);
    const [wetListUser, setWetListUser] = useState(0);
    const [wetListUserLoading, setWetListUserLoading] = useState(false);

    let { slug } = useParams();

    const { address } = useAccount()
    const navigate = useNavigate();

    useEffect(()=> {
        // if(slug !== 'wet-list'){
        navigate('/mint/mint');
        // }
    },[slug]);

    const { data, isLoading } = useContractReads({
        contracts: [
            {
                address: contractAddress,
                abi: abi,
                functionName: 'getRemainWetList',
            },
            {
                address: contractAddress,
                abi: abi,
                functionName: 'maxPerWallet',
            },
            {
                address: contractAddress,
                abi: abi,
                functionName: 'balanceOf',
            },
            {
                address: contractAddress,
                abi: abi,
                functionName: 'priceWetList',
            },
            {
                address: contractAddress,
                abi: abi,
                functionName: 'pricePublic',
            },
            {
                address: contractAddress,
                abi: abi,
                functionName: 'wetListActive',
            },
            {
                address: contractAddress,
                abi: abi,
                functionName: 'publicMintActive',
            },
        ],
    });

    const revealTokenByTxnHash = async (txnHash) => {
        if (txnHash) {  
            try {
                const response = await fetch(`https://cryptofish.us/api/cryptofish/getTokenByTxnHash?txnHash=${txnHash}`);
                const data = await response.json();
                if (data) {
                    toast.success(data.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error(error.message);
            }
        }
    };

    useEffect(()=> {
        let balance = 0;
        setMaxMintQtyLoading(isLoading);
        setMintAmountLoading(isLoading);
        if(data){
            setMaxMintQty(Number(data[1]));
            if(slug === 'mint'){
                setMintingAmount(ethers.utils.formatEther(data[4]));
                let t1 = (1000 * ethers.utils.formatEther(data[4]) * (mintingQty ? mintingQty : 1) * 1000 / 1000);
                let nftPrice1 = (1000000000000000 * t1).toString()
                balance = BigNumber.from(nftPrice1.toString(16));
                setBalanceCalc(BigNumber.from(nftPrice1.toString(16)));
            }else{
                setMintingAmount(ethers.utils.formatEther(data[3]));
            }
        }
    }, [ data, isLoading, slug, mintingQty ])

    const publicMintWrite = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: contractAddress,
        abi: abi,
        functionName: 'publicMint',
        args: [mintingQty],
        overrides: {
            value: balanceCalc,
        },
        onSuccess(data) {
            console.log('mint Success', data)
        },
        onSettled(data, error) {
            if(data) {
                setMintLoading(false);
                getRemaining()
                getWetListUser()
                setMintingQty(1)
                setMintResultLink(baseUrlEtherscan+data.hash)
                revealTokenByTxnHash(data.hash)
                console.log("publicMint data.hash :", data.hash);
            }
        },
        onError(error) {
            alert('Mint Error: ' +  error.code)
            setMintLoading(false);
            getRemaining()
            getWetListUser()
        },
    })
    
    const wetListMintWrite = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: contractAddress,
        abi: abi,
        functionName: 'wetListMint',
        args: [mintingQty],
        onSuccess(data) {
            console.log('wetListMint Success', data)
        },
        onSettled(data, error) {
            console.log('wetListMint Settled', { data, error })
            if(data && data.hash) {
                setMintLoading(false);
                getRemaining()
                getWetListUser()
                setMintingQty(1)
                setMintResultLink(baseUrlEtherscan+data.hash)
                revealTokenByTxnHash(data.hash)
                console.log("wetlist data.hash :", data.hash);
            }
        },
        onError(error) {
            alert('Mint Error: ' +  error.code)
            setMintLoading(false);
            getRemaining()
            getWetListUser()
        },
    })

    useEffect(()=> {
        getRemaining()
        getWetListUser()
        return () => {
            setMintResultLink('')
        }
    },[]);

    useEffect(()=> {
        getRemaining()
        getWetListUser()
    },[address]);

    const mintNftHandler = async ( mintType ) => {
        if(!address){
            toast.warning("🤖 Please connect wallet to proceed")
            return;
        }
        if(!mintingQty){
            toast.warning("Invalid mint quantity 🥶")
            return;
        }
        if(slug === 'wetlist' && Number(wetListUser) === 0){
            toast.error("Oops! 🙊 Eth address not in WetList")
            return;
        }
        if(slug === 'wetlist' && Number(remainingWetList) === 0){
            toast.info("😥 Your WetList claims have already been used")
            return;
        }
        if(slug === 'wetlist' && mintingQty > Number(remainingWetList) ){
            toast.warning(`😑 Can't mint more than ${remainingWetList} with this wallet address`)
            return;
        }
        setMintLoading(true);
        setMintResultLink('');
        if(mintType === 'wetlist'){
            if(data[5]){
                console.log("wetListMintWrite.write")
                wetListMintWrite.write()
            }else{
                alert("Oops!! Mint is not active")
                setMintLoading(false);
            }
        }else{
            if(data[6]){
                console.log("publicMintWrite.write")
                publicMintWrite.write()
            }else{
                alert("Oops!! Mint is not active")
                setMintLoading(false);
            }
        }
    }

    const handleChange = (event) => {
        let { value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setMintingQty(value);
    }

    const getRemaining = async () => {
        setRemainingWetListLoading(true)
        try{
            let remainingWetList = await nftContract.getRemainWetList({"from": address});
            setRemainingWetList(remainingWetList)
            setRemainingWetListLoading(false)
        }catch(error){
            console.log("remainingWetList error :", error);
            setRemainingWetList(0)
            setRemainingWetListLoading(false)
        }
    }

    const getWetListUser = async () => {
        setWetListUserLoading(true)
        try{
            let wetListUser = await nftContract.getWetListUser(address);
            setWetListUser(wetListUser)
            setWetListUserLoading(false)
        }catch(error){
            console.log("remainingWetList error :", error);
            setWetListUser(0)
            setWetListUserLoading(false)
        }
    }

    return (
        <div className='mint-page-content d-flex flex-column justify-content-center align-items-center mx-auto'>
            <div className='mint-page-input-group d-flex justify-content-between align-items-center w-100'>
                <label htmlFor="customRange3" className="form-label">
                    Mint:
                </label>
                <input 
                    type="number" 
                    className="form-input mint-qty-input px-3 mb-2" 
                    min={0} 
                    max={!maxMintQtyLoading ? maxMintQty : 0} 
                    id="customRange3" 
                    onChange={(e) => {handleChange(e)}}
                    placeholder=''
                    value={(mintingQty.toString())}
                />
            </div>
            <div className='mint-page-input-group d-flex flex-column justify-content-center align-items-start w-100'>
                <label htmlFor="customRange3" className="form-label d-flex align-items-center justify-content-between w-100 mb-3">
                    <div>
                        Ea:   
                    </div>
                    <div className="d-flex align-items-center">
                    {mintAmountLoading ? <PulseLoader size={10} color="#36d7b7" /> : (mintingAmount || 0)}
                    &nbsp;ETH.
                    </div>
                </label>
            </div>
            <button 
                className={maxMintQty === 0 ? "button-86 px-5 my-2" : "button-85 px-5 my-2"} 
                onClick={() => {(!mintLoading && maxMintQty !== 0) && mintNftHandler(slug)}}
            >
                {mintLoading ? <PulseLoader size={12} color="#333333" className='mt-1'/> : "MINT"}
            </button>
            <br />
            {mintResultLink &&
                <a 
                    className="text-decoration-none" 
                    href={mintResultLink}
                    target="_blank"
                >
                    View Transaction <TfiArrowTopRight />
                </a>
            }
        </div>
    )
}

export default MintingPage
