import React from 'react';
import HeroImg from '../../assets/img/crypto-fish.png';
import './Hero.css';

function Hero() {
    return (
        <div className='Hero d-flex flex-column align-items-center w-100 mb-4'>
            <div className='Hero-img mt-4 mb-2'>
                <img src={HeroImg} alt='' className='w-100' />
            </div>
            <h1 className='hero-title my-2 text-center'>CryptoFish by NEMO</h1>
            <h5 className='my-2 px-4 px-md-0 mx-4 mx-md-0 text-center'>10,000 CryptoFish On Their Way to the Lost City Of Atlantis</h5>
        </div>
    )
}

export default Hero
