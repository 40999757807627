import React, { useState } from 'react';
import Logo from '../../assets/img/logo.png';
import { Web3Button } from '@web3modal/react';
import { useNavigate } from 'react-router-dom';
import { useAccount, useDisconnect } from 'wagmi';
import { FiMenu, FiX } from "react-icons/fi";
import './Header.css';
import {ConnectButton} from "@rainbow-me/rainbowkit";

function Header({ setIsModalClosed }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const navigate = useNavigate();

    const { address, isConnected } = useAccount()

    const { disconnect } = useDisconnect()

    return (
        <div className='header w-100'>
            <div className='header-wrapper d-flex justify-content-between align-items-center m-auto'>
                <div className='logo p-2 cursor-pointer d-flex align-items-center' onClick={() => {navigate('/')}}>
                    <img src={Logo} alt='' className='w-100' />
                </div>
                <div className='d-none d-md-flex'>
                    {isConnected ?
                        <div className='nav d-flex justify-content-center align-items-center' >
                            <span className='nav-item nav-item-address text-truncate text-center'>{"address:" && address}</span>
                            <button className="btn btn-dark rounded-0" onClick={() => {disconnect();setIsMenuOpen(false)}}>DISCONNECT</button>
                        </div>
                        :
                        <ConnectButton label={'Connect'}/>

                    }
                </div>
                <div className='d-flex d-md-none'  onClick={() => {setIsMenuOpen(!isMenuOpen)}}>
                {isMenuOpen ?
                    <FiX />
                    :
                    <FiMenu />
                }
                </div>
            </div>
            {isMenuOpen ?
                isConnected ?
                    <div className='nav d-flex flex-column justify-content-end align-items-center px-5 py-3' >
                        <span className='nav-item nav-item-address-sm text-truncate text-center mb-2'>{"address:" && address}</span>
                        <button className="btn btn-dark rounded-0" onClick={() => {disconnect();setIsMenuOpen(false)}}>Disconnect</button>
                    </div>
                :
                    <div className='nav d-flex flex-column justify-content-end align-items-center px-5 py-3' >
                        <ConnectButton label={'Connect'}/>
                    </div>
                :
                null
            }
        </div>
    )
}

export default Header
