import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { WagmiConfig, createClient, configureChains, useAccount, mainnet  } from 'wagmi'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { InjectedConnector } from 'wagmi/connectors/injected'
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import MintingPage from './pages/MintingPage/MintingPage';
import Hero from './components/Hero/Hero';
import UseWagmiConnectorsModal from './utils/UseWagmiConnectorsModal';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import '@rainbow-me/rainbowkit/styles.css';
import {connectorsForWallets, darkTheme, lightTheme, RainbowKitProvider} from '@rainbow-me/rainbowkit';
import {
    coinbaseWallet,
    injectedWallet,
    rainbowWallet,
    walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { metaMaskWallet } from '@rainbow-me/rainbowkit/wallets';


const connector = new InjectedConnector({
  options: {
    shimDisconnect: false,
  },
})

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, provider, webSocketProvider } = configureChains(
    [mainnet],
    [infuraProvider({ apiKey: '9fe32d520c064bb38d33baf316ed2eea' }), publicProvider()],
)


const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet( {
        chains: chains,
        shimDisconnect: true,
      }), coinbaseWallet({
    appName: "CoinBase",
    chains:chains
})
    ],
  },
]);


// Set up client
const client = createClient({
  connectors,
});


// Set up client
const clientTemp = createClient({
    autoConnect: true,
    connectors: [
        // new MetaMaskConnector({ chains }),
        new CoinbaseWalletConnector({
            chains,
            options: {
              appName: 'wagmi',
            },
        }),
        new WalletConnectConnector({
            chains,
            options: {
              qrcode: true,
            },
        }),
        new InjectedConnector({
          options: {
            mobileWallets: [
              {
                id: 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
                name: 'MetaMask',
              },
            ]
          },
        })
    ],
    provider,
    webSocketProvider,
});

function App() {
  const [isModalClosed, setIsModalClosed] = useState(false);

  const { address, isConnected } = useAccount();

  // const navigate = useNavigate();

  useEffect(() => {
    if(isConnected){
      setIsModalClosed(false);
    // }else{
    //   navigate('/')
    }
  }, [address, isConnected])


  return (
    <WagmiConfig client={client}>
        <RainbowKitProvider chains={chains} modalSize="compact" theme={lightTheme({
        accentColor: '#000000',
        accentColorForeground: 'white',
            menuItemBackground :'white',
            modalBackground :'white',
            actionButtonBorder :'white',
        borderRadius: 'none',
        fontStack: 'system',
        overlayBlur: 'small',
    })} >
        <div className="App">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <Header setIsModalClosed={setIsModalClosed}/>
          <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
            <Hero />
            <Routes>
              <Route exact path="/" element={ <Navigate to="/mint" /> } />
              <Route exact path="/:slug" element={<Home setIsModalClosed={setIsModalClosed}/> } />
              <Route exact path="/:slug/mint" element={<MintingPage /> } />
            </Routes>
          </div>
        </div>
        { isModalClosed &&
          <UseWagmiConnectorsModal setIsModalClosed={setIsModalClosed}/>
        }
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
