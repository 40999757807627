import React from 'react';
import { toast } from 'react-toastify';
import { useConnect, } from 'wagmi';
import { GrClose } from "react-icons/gr";
import { useEffect } from 'react';
import './UseWagmiConnectorsModal.css';
import { ConnectButton } from '@rainbow-me/rainbowkit';

export default function UseWagmiConnectorsModal({ setIsModalClosed }) {
    const { connect, connectors, error, isLoading, pendingConnector } =  useConnect()

    useEffect(() => {
        if(error){
            toast.error(error.message)
        }
    }, [error]);

    return (
        <div className='connect-wallet-modal-wrapper d-flex justify-content-center align-items-center position-fixed'>
            <div className="connect-wallet-modal d-flex flex-column justify-content-between align-items-center mx-5 mx-md-0 pt-5">
                <div className="connect-wallet-modal-header d-flex justify-content-between align-items-start w-100 px-2 px-md-5">
                    <div className="d-flex flex-column align-items-start">
                        <h3 className="BebasNeueCursive-normal-blue-36px m-0">SELECT WALLET</h3>
                    </div>
                    {/* <div className="connect-wallet-modal-close-btn cursor-pointer" onClick={() => {setIsModalClosed(false)}}>
                        <GrClose size={28}/>
                    </div> */}
                </div>
                <div className="modal-body w-100 p-2 p-md-5">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        {/* {connectors.map((connector) => (
                            // connector.ready ?
                            <button
                                className="btn-cwm btn btn-outline-secondary OpenSansSansSerif-normal-black-16px d-flex justify-content-center align-items-center w-100 py-2 my-2"
                                disabled={!connector.ready}
                                key={connector.id}
                                onClick={() => connect({ connector })}
                            >
                                {connector.name}
                                {!connector.ready && ' (unsupported)'}
                                {isLoading &&
                                    connector.id === pendingConnector?.id &&
                                    ' (connecting)'}
                            </button>
                            // : null
                        ))} */}
                        <ConnectButton.Custom>
                        {({
                            account,
                            chain,
                            openAccountModal,
                            openChainModal,
                            openConnectModal,
                            authenticationStatus,
                            mounted,
                        }) => {
                            // Note: If your app doesn't use authentication, you
                            // can remove all 'authenticationStatus' checks
                            const ready = mounted && authenticationStatus !== 'loading';
                            const connected =
                            ready &&
                            account &&
                            chain &&
                            (!authenticationStatus ||
                                authenticationStatus === 'authenticated');

                            return (
                            <div
                                {...(!ready && {
                                'aria-hidden': true,
                                'style': {
                                    opacity: 0,
                                    pointerEvents: 'none',
                                    userSelect: 'none',
                                },
                                })}
                            >
                                {(() => {
                                if (!connected) {
                                    return (
                                    <button onClick={openConnectModal} type="button">
                                        Connect Wallet
                                    </button>
                                    );
                                }

                                if (chain.unsupported) {
                                    return (
                                    <button onClick={openChainModal} type="button">
                                        Wrong network
                                    </button>
                                    );
                                }

                                return (
                                    <div style={{ display: 'flex', gap: 12 }}>
                                    <button
                                        onClick={openChainModal}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        type="button"
                                    >
                                        {chain.hasIcon && (
                                        <div
                                            style={{
                                            background: chain.iconBackground,
                                            width: 12,
                                            height: 12,
                                            borderRadius: 999,
                                            overflow: 'hidden',
                                            marginRight: 4,
                                            }}
                                        >
                                            {chain.iconUrl && (
                                            <img
                                                alt={chain.name ?? 'Chain icon'}
                                                src={chain.iconUrl}
                                                style={{ width: 12, height: 12 }}
                                            />
                                            )}
                                        </div>
                                        )}
                                        {chain.name}
                                    </button>

                                    <button onClick={openAccountModal} type="button">
                                        {account.displayName}
                                        {account.displayBalance
                                        ? ` (${account.displayBalance})`
                                        : ''}
                                    </button>
                                    </div>
                                );
                                })()}
                            </div>
                            );
                        }}
                        </ConnectButton.Custom>
                    </div>  
                </div>
            </div>
        </div>
    )
}
