import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import './Home.css';
import {ConnectButton} from "@rainbow-me/rainbowkit";

function Home({ setIsModalClosed }) {
    const { isConnected } = useAccount()

    const navigate = useNavigate();

    let { slug } = useParams();

    useEffect(()=> {
        if(slug !== 'wetlist')navigate('/mint');
    },[slug]);

    return (
        <div className='home d-flex flex-column justify-content-center align-items-center'>
            <div className='home-content text-center'>
                <div className=' d-flex flex-column justify-content-center align-items-center my-2 my-md-5'>
                    {!isConnected ?
                        <button
                            className="button-85 px-4 px-md-5"
                            role="button"
                            // onClick={() => {setIsModalClosed(true)}}
                        >
                            {/* CONNECT WALLET */}
                            <ConnectButton label={'MINT'}/>
                            {/*end*/}
                        </button>
                        :
                        <button
                            className={isConnected ? "button-85 px-4 px-md-5" : "button-86 px-4 px-md-5"}
                            role="button"
                            onClick={() => {slug === 'wetlist' ? navigate('/wetlist/mint') : navigate('/mint/mint')}}
                        >
                            MINT
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default Home
